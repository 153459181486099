import LayoutA2 from "../../components/layout-a2"
import React from "react"
import Login from "../../components/login"


export default class A2Login extends React.Component {

  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA2/profile/enrollInfoList"
    return(
      <LayoutA2  >
        <Login color={color} src={src} />
      </LayoutA2>
    )
  }
}